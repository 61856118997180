.logo-white {
  display: none;
}
.logo-black {
  display: block;
}

.bg-transparent-nav:not(.navbar-stuck) {
  .logo-black {
    display: none;
  }
  .logo-white {
    display: block;
  }
  .nav-link,
  .nav-item > a {
    color: #fff;
    &:hover {
      color: #ddd;
      a {
        color: #ddd;
      }
    }
  }

  .nav-item {
    &:hover {
      color: #ddd;
    }
  }

  .nav-item:hover > .nav-link:not(.disabled),
  .nav-item .nav-link.show:not(.disabled) {
    color: #ddd;
  }
  .dropdown:hover > .dropdown-toggle:not(.disabled) {
    color: #ddd;
  }
}

@media (max-width: 900px) {
  .bg-transparent-nav:not(.navbar-stuck) {
    .logo-black {
      display: none;
    }
    .logo-white {
      display: block;
    }
    .nav-link,
    .nav-item > a {
      color: #000;
      &:hover {
        color: #333;
        a {
          color: #333;
        }
      }
    }

    .nav-item {
      &:hover {
        color: #333;
      }
    }

    .nav-item:hover > .nav-link:not(.disabled),
    .nav-item .nav-link.show:not(.disabled) {
      color: #333;
    }
    .dropdown:hover > .dropdown-toggle:not(.disabled) {
      color: #333;
    }
  }
}

.navbar-stuck {
  border-bottom: 1px solid rgba(77, 77, 77, 0.1);
}

h1,
h2,
.display-1,
.display-2,
.display-3 {
  font-weight: 700;
  line-height: 1.05;
  letter-spacing: -1.5px;
}

.main-title-pawel-idea {
  h2,
  .display-3 {
    letter-spacing: 0.45rem;
    @media (max-width: 900px) {
      letter-spacing: 0.17rem;
    }
  }
  h1,
  .h2 {
    padding-left: 10px;
    font-weight: 200;
    letter-spacing: 1.48rem;
    @media (max-width: 900px) {
      padding-left: 2px;
      letter-spacing: 0.53rem;
    }
  }
}

.lg-on {
  overflow: hidden;
}

.form-group:has(input[required]) .form-label::after {
  content: " *";
  color: red;
}

.partner-img {
  max-width: 192px;
  max-height: 70px;
  object-fit: contain;
  &.even-smaller {
    max-width: 140px;
    max-height: 60px;
  }
}
