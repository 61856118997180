//
// user-variables.scss
// Use this to override Bootstrap and Around variables
//

// Example of a variable override to change body background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #f6f9fc;


$navbar-nav-link-padding-x:                 0.5rem !default;
$font-family-sans-serif:                    "Twemoji Country Flags", "Inter", sans-serif !default;
